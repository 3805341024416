<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col
                v-if="active_role == 'superadmin'"
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Corporate"
                  label-for="title"
                >
                  <b-form-select
                    id="country"
                    v-model="data.corporate"
                    :options="corporate"
                    value-field="id"
                    text-field="name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="data.title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Link"
                  label-for="link"
                >
                  <b-form-input
                    id="link"
                    v-model="data.link"
                    placeholder="Link"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="data.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Upload Logo"
                  label-for="upload_logo"
                >
                  <b-form-file
                    ref="file-input"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="setFileImage"
                  />
                </b-form-group>
                <div
                  v-if="data.file.url"
                  style="position: relative; width: 50%"
                >
                  <b-img
                    :src="data.file.url"
                    rounded
                    alt="Rounded image"
                    class="d-inline-block mr-1 mb-1 w-100"
                  />
                  <div
                    style="position: absolute; top: 0.45rem; right: 0.5rem"
                    @click="resetImage"
                  >
                    <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  @click="submit"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="crop-image"
      ref="cropimage"
      v-model="modal_crop_image"
      static
      title="Crop Gambar"
      @hidden="resetCropImage"
      @ok="handleCropImage"
    >
      <vue-cropper
        ref="cropper"
        :src="data.file_raw"
        :crop-box-resizable="false"
        :toggle-drag-mode-on-dblclick="false"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="setCropArea"
      >
        Set Default Crop
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardBody,
  BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormSelect, BButton,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormSelect,
    BButton,
    BImg,
    VueCropper,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        title: null,
        link: null,
        description: null,
        corporate: null,
        file: {
          url: null,
          image: null,
        },
        file_raw: null,
      },
      modal_crop_image: false,
      active_role: null,
    }
  },
  computed: {
    corporate() {
      return this.$store.state.corporate.corporate
    },
  },
  created() {
    this.checkAuth()
    this.getCorporate()
    this.active_role = localStorage.getItem('role')
  },
  methods: {
    getCorporate() {
      this.$store.dispatch('corporate/loadCorporate')
    },
    setFileImage(event) {
      const file = event.target.files[0]

      if (file.type.indexOf('image/') === -1) {
        this.$refs['file-input'].reset()
        this.runToast('error', 'Please select an image file')
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = eventReader => {
          const sizeFile = Math.floor(file.size / 1024)

          if (sizeFile <= 1024) {
            this.data.file_raw = eventReader.target.result

            this.showModalCrop()

            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(eventReader.target.result)
          } else {
            this.$refs['file-input'].reset()
            this.runToast('error', 'Ukuran file lebih dari 1 MB')
          }
        }
        reader.readAsDataURL(file)
      } else {
        this.$refs['file-input'].reset()
        this.runToast('error', 'Sorry, FileReader API not supported')
      }
    },
    submit() {
      const cid = localStorage.getItem('client_id')
      const input = this.data
      const data = new FormData()

      data.append('title', input.title)
      data.append('image', input.file.image)
      data.append('description', input.description)
      data.append('link', input.link)
      data.append('corporate', input.corporate)

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }

      this.$axios.post(`${cid}/banner`, data, config)
        .then(() => {
          // Success code
          this.$bvToast.toast('Success', {
            title: 'Add Banner',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
            autoHideDelay: 3000,
          })

          setTimeout(() => {
            this.$router.push({ path: '/banner' })
          }, 3000)
        })
        .catch(error => {
          console.log(error)
        })
    },

    showModalCrop() {
      this.modal_crop_image = true
    },
    resetImage() {
      this.$refs['file-input'].reset()

      this.data.file.image = null
      this.data.file.url = null
      this.data.file_raw = null
    },
    setCropArea() {
      this.$refs.cropper.setCropBoxData({
        width: 512,
        height: 512,
      })
    },
    resetCropImage() {
      if (!this.data.file_raw) {
        this.$refs['file-input'].reset()
      }
    },
    handleCropImage() {
      this.data.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()

      const block = this.data.file.url.split(';')
      const contentType = block[0].split(':')[1]
      const realData = block[1].split(',')[1]
      this.data.file.image = this.b64toBlob(realData, contentType)
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
  },
}
</script>
